import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Agregar nuevo producto";

  const [estado, setEstado] = useState(ESTADOS.CARGADO);
  const [enviando, setEnviando] = useState(false);

  const navigate = useNavigate();
  const peticion = usePeticion();

  function handleSubmit(e) {
    e.preventDefault();
    setEnviando(true);
    let formData = new FormData(e.currentTarget);
    peticion("mm01/consultar_codigo/", { method: "POST", body: formData })
      .then((json) => {
        if (json.duplicado) {
          alert("Este código ya exite en otro producto");
          setEnviando(false);
        } else {
          setEstado(ESTADOS.CARGANDO);
          peticion("mm01/proceso/", {
            method: "POST",
            body: formData,
          })
            .then((json) => {
              setEstado(ESTADOS.EXITO);
            })
            .catch((e) => alert("El producto no ha podido ser agregado correctamente: " + e.message));
        }
      })
      .catch((e) => {
        alert("No se ha podido comprobar la existencia del código: " + e.message);
        setEnviando(false);
      });
  }

  function resetear() {
    setEstado(ESTADOS.CARGADO);
    setEnviando(false)
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <form method="post" className="row g-3" onSubmit={handleSubmit} autoComplete="off">
        <div className="col-md-8">
          <label htmlFor="nombre" className="form-label">
            Código | Nombre
          </label>
          <div className="input-group d-flex">
            <input type="text" required="" className="form-control flex-grow-1" name="codigo" id="codigo" />
            <input type="text" required="" className="form-control" style={{ flex: 3 }} name="nombre" id="nombre" />
          </div>
        </div>
        <div className="col-md-4">
          <label htmlFor="imagen" className="form-label">
            Imagen
          </label>
          <input accept="image/*" className="form-control" name="imagen" type="file" id="imagen" />
        </div>
        <div className="col-md-4">
          <label htmlFor="precio_importador" className="form-label">
            Precio importador
          </label>
          <input type="number" min="0" required="" step="0.01" className="form-control" name="precio_importador" id="precio_importador" defaultValue="0" />
        </div>
        <div className="col-md-4">
          <label htmlFor="precio_final" className="form-label">
            Precio final
          </label>
          <input type="number" min="0" required="" step="0.01" className="form-control" name="precio_final" id="precio_final" defaultValue="0" />
        </div>
        <div className="col-md-4">
          <label htmlFor="comision" className="form-label">
            Puntaje por venta
          </label>
          <input type="number" min="0" required="" step="0.01" className="form-control" name="puntaje_venta" id="puntaje_venta" defaultValue="0" />
        </div>
        <div className="col-md-4">
          <label htmlFor="cantidad_buen_estado" className="form-label">
            Cantidad buen estado
          </label>
          <input type="number" min="0" required="" step="1" className="form-control" name="cantidad_buen_estado" id="cantidad_buen_estado" defaultValue="0" />
        </div>
        <div className="col-md-4">
          <label htmlFor="cantidad_para_liquidacion" className="form-label">
            Cantidad para liquidación
          </label>
          <input
            type="number"
            min="0"
            required=""
            step="1"
            className="form-control"
            name="cantidad_para_liquidacion"
            id="cantidad_para_liquidacion"
            defaultValue="0"
          />
        </div>
        <div className="col-md-4">
          <label htmlFor="cantidad_merma" className="form-label">
            Cantidad merma
          </label>
          <input type="number" min="0" required="" step="1" className="form-control" name="cantidad_merma" id="cantidad_merma" defaultValue="0" />
        </div>
        <div className="col-md-12">
          <label htmlFor="link" className="form-label">
            Link
          </label>
          <input type="url" className="form-control" name="link" id="link" />
        </div>
        <div className="col-md-12">
          <label htmlFor="detalle" className="form-label">
            Detalle
          </label>
          <textarea className="form-control" id="detalle" name="detalle" rows="5" />
        </div>
        <div className="col-12">
          {enviando ? (
            <button type="submit" className="btn btn-success me-3 mb-3" disabled>
              <span className="spinner-border spinner-border-sm" aria-hidden="true"></span> Agregando producto
            </button>
          ) : (
            <button type="submit" className="btn btn-success me-3 mb-3">
              Agregar producto
            </button>
          )}
          <button type="button" className="btn btn-danger me-3 mb-3" onClick={() => navigate(-1)}>
            Cancelar
          </button>
        </div>
      </form>
    );
  } else if (estado === ESTADOS.EXITO) {
    contenido = (
      <Exito titulo="Producto agregado con éxito" mensaje="El producto ha sido agregado de forma satisfactoria">
        <button type="button" className="btn btn-primary" onClick={resetear}>
          Agregar otro producto
        </button>
      </Exito>
    );
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
