import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink, useParams } from "react-router-dom";
import { usePeticion, ESTADOS, Exito } from "../herramientas";
import { API_URL } from "../config";

export default function Index() {
  const nombreOperacion = "Modificar pedido abierto";

  const [estado, setEstado] = useState(ESTADOS.CARGANDO);
  const [datos, setDatos] = useState({});
  const [detalle, setDetalle] = useState();

  const navigate = useNavigate();
  const peticion = usePeticion();
  const { id_pedido } = useParams();

  useEffect(inicializar, []);

  function inicializar() {
    peticion("va02/modificar_pedido/" + id_pedido, {})
      .then((json) => {
        setDatos(json.datos);
        setDetalle(json.detalle);
        setEstado(ESTADOS.CARGADO);
        if (json.datos.estado != "abierto") {
          alert("Este pedido no está abierto")
          navigate("../")
        }
      })
      .catch((e) => {
        alert("No ha podido ser cargado el pedido: " + e.message);
      });
  }

  function handleDeclararCompletado() {
    if (window.confirm("¿Está seguro de declarar este pedido como completado? Una vez declarado no podrá modificarlo.")) {
      let formData = new FormData();
      formData.append("id_pedido", id_pedido);
      peticion("va02/declarar_pedido_completado/", { method: "POST", body: formData })
        .then(() => {
          setEstado(ESTADOS.EXITO)
        })
        .catch((e) => alert("No ha podido ser declarado como completado el pedido: " + e));
    }
  }

  function handleEliminarItem(idArticulo) {
    if (window.confirm("¿Está seguro de eliminar este artículo del pedido?")) {
      setEstado(ESTADOS.CARGANDO);
      let formData = new FormData();
      formData.append("id_articulo_pedido", idArticulo);
      peticion("va02/eliminar_articulo_pedido/", { method: "POST", body: formData })
        .then(() => {
          inicializar();
        })
        .catch((e) => {
          setEstado(ESTADOS.CARGADO);
          alert("No ha podido ser eliminado el artículo del pedido " + e);
        });
    }
  }

  let contenido;

  if (estado === ESTADOS.CARGANDO) {
    contenido = (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else if (estado === ESTADOS.EXITO) {
    contenido = <Exito titulo="Pedido declarado como completado" mensaje="El pedido ha sido declarado como declarado exitosamente.">
      <Link role="button" to="../" className="btn btn-primary me-3 mb-3">Modificar otros pedidos abiertos</Link>
      <Link role="button" to={"../../va03/consultar/"+id_pedido} className="btn btn-primary me-3 mb-3">Consultar pedido completado</Link>
    </Exito>
  } else if (estado === ESTADOS.CARGADO) {
    contenido = (
      <>
        <h2>
          Datos{" "}
          <Link role="button" to={"../modificar_datos/" + id_pedido} className="btn btn-warning btn-sm">
            <i className="bi bi-pencil"></i> Modificar
          </Link>
        </h2>
        <div className="row">
          <div className="col-md">
            <table className="table">
              <tbody>
                <tr>
                  <th className="text-center" scope="col">
                    Número
                  </th>
                  <td className="align-middle text-center">{datos["id_pedido"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Cliente
                  </th>
                  <td className="align-middle text-center">{datos["nombre_cliente"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Teléfono
                  </th>
                  <td className="align-middle text-center">{datos["telefono"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Vendedor
                  </th>
                  <td className="align-middle text-center">{datos["nombre_vendedor"]}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Fecha
                  </th>
                  <td className="align-middle text-center">{`${new Date(datos["fecha"]).getUTCDate()}/${new Date(datos["fecha"]).getUTCMonth() + 1}/${new Date(
                    datos["fecha"]
                  ).getUTCFullYear()}`}</td>
                </tr>
                <tr>
                  <th className="text-center" scope="col">
                    Tipo de entrega
                  </th>
                  <td className="align-middle text-center">{datos["tipo_entrega"]}</td>
                </tr>
                {datos.tipo_entrega == "Envío" && (
                  <tr>
                    <th className="text-center" scope="col">
                      Costo de envío
                    </th>
                    <td className="align-middle text-center">S/ {datos["costo_envio"]}</td>
                  </tr>
                )}
                <tr>
                  <th className="text-center" scope="col">
                    Total
                  </th>
                  <td className="align-middle text-center">S/ {datos["total"]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row g-3 mb-3">
          <div className="col">
            <button type="button" onClick={handleDeclararCompletado} className="btn btn-primary w-100 h-100">
              Declarar completado...
            </button>
          </div>
          <div className="col">
            <Link role="button" to="../" className="btn btn-secondary w-100 h-100">
              Regresar
            </Link>
          </div>
          <div className="col">
            <Link role="button" to={"../agregar_item/" + id_pedido} className="btn btn-success w-100 h-100">
              Agregar ítem
            </Link>
          </div>
        </div>
        <h2>Detalle</h2>
        <div className="table-responsive">
          <table className="table table-hover table-sm table-bordered">
            <thead>
              <tr>
                <th className="align-middle text-center" scope="col">
                  Cant.
                </th>
                <th colSpan={3} className="align-middle text-center" scope="col">
                  Ítem
                </th>
                <th className="align-middle text-center" scope="col">
                  Fecha
                </th>
                <th className="align-middle text-center" scope="col">
                  Precio
                </th>
                <th className="align-middle text-center" scope="col">
                  Importe
                </th>
                <th colSpan={3} className="align-middle text-center" scope="col">
                  Pagado
                </th>
                <th className="align-middle text-center" scope="col">
                  Elim.
                </th>
              </tr>
            </thead>
            <tbody id="tabla">
              {detalle.map((item) => (
                <tr>
                  <td className="align-middle text-center">{item["cantidad"]}</td>
                  <td className="align-middle text-center">
                    <img
                      alt=""
                      src={API_URL + "static/img/productos/" + item["id_producto"] + "/principal.jpeg"}
                      className="rounded"
                      style={{ height: "5rem" }}
                    />
                  </td>
                  <td className="align-middle text-center">{item["codigo_producto"]}</td>
                  <td className="align-middle text-center">{item["nombre_producto"]}</td>
                  <td className="align-middle text-center">{(new Date(item["fecha"]).toLocaleString())}</td>
                  <td className="align-middle text-center">{item["precio_final"]}</td>
                  <td className="align-middle text-center">{item["importe"]}</td>
                  {item.pagado ? <td className="align-middle text-center table-success">Sí</td> : <td className="align-middle text-center table-danger">No</td>}
                  <td className="align-middle text-center">{item["numero_operacion"]}</td>
                  <td className="align-middle text-center">
                    <Link role="button" className="btn btn-warning" to={"../modificar_item/"+item.id_articulo_pedido}>
                      <i className="bi bi-pencil"></i>
                    </Link>
                  </td>
                  <td className="align-middle text-center">
                    <button type="button" className="btn btn-danger" onClick={() => handleEliminarItem(item["id_articulo_pedido"])}>
                      <i className="bi bi-x-lg"></i>
                    </button>
                  </td>
                </tr>
              ))}

              <tr>
                <th colSpan="3" className="align-middle text-center"></th>
                <th colSpan="3" className="align-middle text-center">
                  Costo de envío
                </th>
                <th className="align-middle text-center">{datos["costo_envio"]}</th>
                <th colSpan={4} className="align-middle text-center"></th>
              </tr>
              <tr>
                <th colSpan="3" className="align-middle text-center"></th>
                <th colSpan="3" className="align-middle text-center">
                  Total
                </th>
                <th className="align-middle text-center">{datos["total"]}</th>
                <th colSpan={4} className="align-middle text-center"></th>
              </tr>
            </tbody>
          </table>
        </div>
        <h2>Observaciones</h2>
        <p>
          {datos["observaciones"].split("\n").map((parrafo) => {
            return (
              <>
                {parrafo}
                <br />
              </>
            );
          })}
        </p>
      </>
    );
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      {contenido}
    </main>
  );
}
